import { Link } from "react-router-dom";
import AvendiLogo from "../../assets/images/logo.svg";

import { appService } from "../../services/appService";
import { DynamicRoutes } from "../../constants/routes";
import LanguageSelect from "../language/LanguageSelect";
import { checkLanguageTranslationStatus } from "../../services/manageModuleService";
import { getValue } from "../../utils/object";
import { useEffect, useState } from "react";

// Logo -> White kgh logo
export default function AuthHeader() {
  const [translationEnabled, setTranslationEnabled] = useState(false);

  const data = appService.getTenantData();
  const logo = data.guestAppLogoUrl || data.imageUrl || AvendiLogo;
  const logoClasses = `dynamic_logo ${
    data.clientSettings?.logoLargeAndCentered && "dynamic_logo--large"
  }`;
  const logoContainerClasses = `logo add_logo ${
    data.clientSettings?.logoLargeAndCentered && "m-auto"
  }`;

  useEffect(() => {
    const fetchLanguageTranslationStatus = async () => {
      const hotelName = getValue(data, "username");
      if (!hotelName) return;
      const response = await checkLanguageTranslationStatus(hotelName);
      const translationStatus = getValue(response, "data.enabled", false);
      sessionStorage.setItem("langTranslationStatus", translationStatus);
      setTranslationEnabled(translationStatus);
    };
    fetchLanguageTranslationStatus();
  }, [data]);

  return (
    <header
      className="header_area"
      id="authHeader"
      style={{ background: "#fafafa" }}
    >
      <div className="header-row">
        <div className={logoContainerClasses}>
          <Link to={DynamicRoutes.Login}>
            <img className={logoClasses} src={logo} alt="logo" />
          </Link>
        </div>
        {!!data.clientSettings.guestAppHeaderRightLogo && (
          <div>
            <img
              src={data.clientSettings.guestAppHeaderRightLogo}
              alt="right_logo"
              className={logoClasses}
            />
          </div>
        )}

        {translationEnabled ? <LanguageSelect /> : <></>}
      </div>
    </header>
  );
}
